@import 'Colors.scss';

html {
    scroll-behavior: smooth;
}

* {
    margin: 0;
    padding: 0;
}

body {
    font-family: "Gilmer Regular", sans-serif;
}

.bold {
    font-family: "Gilmer Medium", sans-serif;
}

.bolder {
    font-family: "Gilmer Bold", sans-serif;
}

.cognni-blue-text {
    color: $cognniBlue;
}