@font-face {
  font-family: 'Gilmer Regular';
  src: url("./Gilmer-Font/Gilmer Regular.woff") format("opentype");
}
  
@font-face {
  font-family: 'Gilmer Outline';
  src: url("./Gilmer-Font/Gilmer Outline.woff") format("opentype");
}

@font-face {
  font-family: 'Gilmer Bold';
  src: url("./Gilmer-Font/Gilmer Bold.woff") format("opentype");
}

@font-face {
  font-family: 'Gilmer Medium';
  src: url("./Gilmer-Font/Gilmer Medium.woff") format("opentype");
}

@font-face {
  font-family: 'Gilmer Light';
  src: url("./Gilmer-Font/Gilmer Light.woff") format("opentype");
}

@font-face {
  font-family: 'Gilmer Heavy';
  src: url("./Gilmer-Font/Gilmer Heavy.woff") format("opentype");
}

