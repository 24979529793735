@import '../Colors.scss';

.question-page-wrapper {
    min-height: 100vh;
    background-image: url('./assets/bg.png');
    background-size: cover;
    padding-bottom: 5rem;

    .question-page-cta {
        justify-content: center;
        margin: 1.5rem 0 0 0;
        .cta-button {
            margin: 0rem 2rem;
            transition: 0.15s ease-in-out;
            &:active, &:focus {
                background-color: black;
                transition: 0.15s ease-in-out;
                color: white;
            }
        }
        .cta-button.filled {
            &:active, &:focus {
                background-color: white;
                transition: 0.15s ease-in-out;
                color: $cognniBlue;
            }
        }
    }

    .question-page-content {
        min-height: 60vh;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 0 1rem;

        .row {
            margin: 5vh 0;
        }

    }

}