@import '../../Colors.scss';

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 1rem;

    .header-links a {
        padding: 1rem 1rem;
        margin: 0 1rem;
        color: inherit;
        position: relative;
    }

    .header-link--outline::before {
        border: 1px solid #000;
        border-radius: 2rem;
        content: " ";
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 100%;
        top: 0rem;
        left: 0rem;
    }

    .header-link--button {
        background-color: $cognniBlue;
        border-radius: 2rem;
        color: white !important;
        font-family: 'Gilmer Bold', sans-serif;
        transition: 0.2s ease-in-out;

        &:hover {
            background-color: darken($cognniBlue, 5%);
            transition: 0.2s ease-in-out;
        }

    }

}