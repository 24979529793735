footer {
    background-color: #F8F8F8;
    width: 100%;
    padding: 4rem 5rem;

    .col {
        @media (max-width: 1180px) {
            padding-bottom: 5rem !important;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
        }
    }

    .footer-logo-section {

        .line {
            display: inline-block;
        }

        .footer-logo {
            font-family: "Gilmer Medium", sans-serif;
            font-size: 1.25rem;
            letter-spacing: 0.2rem;
            color: #34314C;
        }

        .application-tagline {
            font-family: "Gilmer Regular", sans-serif;
            margin: 2rem 0;
            line-height: 1.5rem;
            font-size: 1rem;
            color: #828282;
            max-width: 18rem;
        }

        .footer-socials img {
            margin-right: 1.25rem;
            cursor: pointer;
            &:hover {
                opacity: 0.6; 
            }
        }

    }

    .resource-link, .company-link {
        font-family: "Gilmer Regular", sans-serif;
        line-height: 1.5rem;
        font-size: 1rem;
        color: #565656;
        margin: 1.25rem 0;
        cursor: pointer;
        display: block;

        &:hover {
            opacity: 0.6;
        }

    }

    .resource-heading, .company-heading, .footer-touch-heading {
        font-family: "Gilmer Bold", sans-serif;
        font-size: 1.15rem;
        letter-spacing: 1px;
        color: #37306C;
        margin-bottom: 2.25rem;
    }

    .get-touch-input {
        max-width: 25rem;
        padding: 0 2.5rem;
        display: flex;
        border-radius: 2rem;
        border: solid 1px #AEAEAE;
        
        input.email-input {
            font-family: "Gilmer Regular", sans-serif;
            font-size: 1.15rem;
            color: #828282;
            border: none !important;
            box-shadow: none !important;
            margin-right: 1rem;
        }

        img {
            cursor: pointer;
            &:hover {
                opacity: 0.6;
            }
        }

    }

    .footer-copyright {
        font-family: "Gilmer Regular", sans-serif;
        font-size: 0.9rem;
        line-height: 1rem;
        color: #828282;
        margin-top: 4rem;
    }

    .fab {
        padding-right: 1rem;
        padding-top: 1rem;
    }

}