.thank-you-page {
    background-image: url('./assets/bg.png');
    min-height: 100vh;
    position: relative;

    .thank-you-content {
        position: relative;
        z-index: 1;
    }

    .thank-you-graphic {
        position: absolute;
        bottom: 0;
        left: 10%;
        width: 80%;
        transform-origin: 50% 100%;
        transform: scale(0.65);
    }

}