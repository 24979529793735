@import '../../Colors.scss';

.item-heading {
    margin: 2rem 0;
}

.item-text {
    line-height: 1.5;
}

.item-list {
    line-height: 1.5;
    padding: 2rem 0;
    margin-left: 1.25rem;
    list-style-image: url('../assets/list_bullet.png');

    .item-list-item {
        padding: 0.5rem 0;
    }

}

.item-form {
    
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 25rem;
    width: 75%;
    margin: 0 auto;
    padding: 2rem 0;

    .form-content {
        box-shadow: 0px 4.08392px 30.6294px rgba(0, 0, 0, 0.1);
        padding: 2rem;
        border-radius: 0.6rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        position: relative;

        .form-item-banner {
            position: absolute;
            right: 0;
            top: 0;
        }

        div {
            width: 100%;
            text-align: center;
            padding-bottom: 0.5rem;
        }

    }

    .form-item.clicked {
        background-color: white;
        transition: 0.15s ease-in-out;
        color: $cognniBlue;
    }
    
    .form-item-input-group {

        .col.s6 {
            margin-bottom: 0;
        }

        input {
            border: 1px solid rgba(0, 0, 0, 0.1);
            padding-left: 0.5rem;
            text-indent: 0.5rem;
            border-radius: 0.5rem;
            width: 100%;
        }

        input.empty {
            border: solid rgba(lightcoral, 0.5) 0.5px;
        }

        margin-bottom: 0;
        margin-top: 1rem;

    }

    .form-item-info {
        font-size: 0.85rem;
        p {
            text-align: left;
            padding: 0 1rem;
        }
    }

    
    .form-item-cta {
        width: 100%;
    }
    
}

.form-item-cta-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.form-item-cta {
    background-color: $formCta;
    padding: 0 1rem;
    width: fit-content;
    border-radius: 2rem;
    text-align: left;
    text-transform: initial;
    color: white;
    margin: 1rem 0;

    &:hover {
        background-color: darken($formCta, 10%);
    }

    &:active, &:focus {
        background-color: white;
        transition: 0.15s ease-in-out;
        color: $cognniBlue;    
    }
    
}


.video-container.invisible {
    opacity: 0;
    display: none;
}

.image-container {
    padding: 1rem;
    width: 100%;
    text-align: center;
    img {
        width: 80%;
    }
}

.item-cta-button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.item-cta-button {
    background-color: $formCta;
    width: fit-content;
    border-radius: 2rem;
    text-transform: initial;
    color: white;
    margin: 1rem 0;

    &:hover {
        background-color: darken($formCta, 10%);
    }
}


.meeting-widget-modal-container {
    
    @media screen and (max-width: 680px) {
        transform: scale(0.8) !important;
        margin-top: 1rem !important;
    }

    position: relative;

    .modal-close {
        position: absolute;
        top: 1rem;
        right: 1rem;
        color: black;
        i {
            font-size: 2rem;
        }
    }

}