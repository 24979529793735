.landing-page {
    
    .landing-page-hero {
        background-image: url('./assets/bg.png');
        background-size: cover;
        min-height: 100vh;
        
        .landing-page-left, .landing-page-right {
            min-height: 50vh;
            display: flex;
            justify-content: center;
            flex-direction: column;
        }

    }

}

.landing-page-content {

    background-image: url('./assets/content_row_bg.svg');
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 50vh;
    padding: 5rem 0;

    .landing-page-content-title {
        text-align: center;
    }

    .content-row {
        padding: 5rem 0;
    }

}


.video-thumbnail {
    position: relative;

    .play-btn {
        height: 100px;
        width: 100px;
        position: absolute;
        left: 50%;
        margin-left: -50px;
        top: 50%;
        margin-top: -50px;
        cursor: pointer;
    }

}