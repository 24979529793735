@import '../../Colors.scss';

.cta-button {
    padding: 0.5rem 1rem;
    margin: 0 0.5rem;
    border-radius: 2rem;
    border: solid 1px black;
    min-width: 5rem;
    font-size: 0.85rem;
    font-family: "Gilmer Medium", sans-serif;
    user-select: none;
    cursor: pointer;
}

.cta-button.filled {
    background-color: $cognniBlue;
    border-color: transparent;
    color: white;

    &:hover {
        background-color: darken($cognniBlue, 5%);
    }

}

.cta-button.clicked {
    background-color: black;
    transition: 0.15s ease-in-out;
    color: white;
}
.cta-button.filled.clicked {
    background-color: white;
    transition: 0.15s ease-in-out;
    color: $cognniBlue;
}